<template>
  <div class="footer-inside">
    <div class="footer-left">
      <div class="box" valign="center" gap="xl">
        <Logo
          :type="`symbol`"
          :width="`36`"
        />
        <div class="box" direction="column" gap="3xs">
          <p fsize="xs">
            {{ $t('copyright-license') }}
            <span color="xs" pdr="3xs">
              <v-icon icon="mdi-slash-forward" fsize="xs" />
            </span>
            <span color="xl">{{ $t('copyright-email-id') }}@{{ $t('copyright-email-domain') }}</span>
          </p>
          <p fsize="2xs" color="md">
            {{ $t('copyright-ltd') }}
            {{ $t('copyright-allright') }}
          </p>
        </div>
      </div>
    </div>
    <div class="footer-right">
      <div class="box" valign="center">
        <span pdr="sm">
          <component
            :is="LanguageMenu"
            :fsize="`sm`"
            :flag="18"
          />
        </span>
        <span color="sm">
          <v-icon icon="mdi-slash-forward" fsize="xs" />
        </span>
        <v-btn to="/our" :title="$t('aboutus')" variant="text">
          {{ $t('aboutus') }}
        </v-btn>
        <span color="sm">
          <v-icon icon="mdi-slash-forward" fsize="xs" />
        </span>
        <v-btn to="/our/agreement" :title="$t('agreement')" variant="text">
          {{ $t('agreement') }}
        </v-btn>
        <span color="sm">
          <v-icon icon="mdi-slash-forward" fsize="xs" />
        </span>
        <v-btn to="/our/privacy" :title="$t('privacy')" variant="text">
          {{ $t('privacy') }}
        </v-btn>
        <component
          :is="SocialLink"
          :size="`small`"
          mgr="xs-neg"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// import LanguageMenu from '~/component/element/language-menu.vue'
// import SocialLink from '~/component/element/social-link.vue'

export default {
  setup () {

    // 메모리 비우기
    function restore () {
      LanguageMenu.value = null
      SocialLink.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const LanguageMenu: any = shallowRef(null)
    const SocialLink: any = shallowRef(null)
    const loadComponent = async () => {
      const LanguageMenu_load = await import('~/component/element/language-menu.vue')
      const SocialLink_load = await import('~/component/element/social-link.vue')
      LanguageMenu.value = LanguageMenu_load.default || LanguageMenu_load
      SocialLink.value = SocialLink_load.default || SocialLink_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      LanguageMenu,
      SocialLink
    }
  }
}
</script>
